import {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {SearchBar} from '@app/components/widgets/searchBar';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {useTheme} from '@mui/material/styles';
import {
  planAndMeetStatusStateActions,
  fetchPlanAndMeetListCreator,
  planAndMeetSelector,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import {useStyles} from './styles';
import {
  getRoleIdFromRolesList,
  getMonthsArray,
  downloadExcel,
} from '@app/helper/plan-and-meet';
import {Label} from '@app/components/elements/Label';
import {Button, Switch} from '@mui/material';
import excelLogo from '@app/assets/images/excelLogo.png';
import {filterBasedDownloadExcelCreator} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/slice';
import {filterSelector} from '@app/components/right-sidebar/redux';
import {approvalWorkflowSelector} from '@app/screens/configuration-management/pages/approval-workflow/redux';
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: any, rolesData: any, theme: any) {
  return {
    fontWeight:
      rolesData.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const getMonthsArrayForTLM = () => {
  return [0, 1, 2, 3, 4, 5, 6].map((month: any) =>
    moment().add(month, 'months').format('MMM'),
  );
};
const getNextSixMonths = (selectedMonth: string | undefined) => {
  const months = [];

  for (let i = 0; i < 7; i++) {
    const nextMonth = moment().add(i, 'months');
    const monthObj = {
      month: nextMonth.format('M'),
      year: nextMonth.format('YYYY'),
    };
    months.push(monthObj);
  }
  return months.find(
    monthObj =>
      monthObj.month ===
      moment()
        .month(selectedMonth ?? '')
        .format('M'),
  );
};

const valuesPresent = (array: any[], searchValues: any[]) =>
  searchValues.every(value => !array.includes(value));

export const PlanAndMeetNavBar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [dropdownValue, setDropdownValue] = useState(['All']);
  const [monthDropDownValue, setMonthDropDownValue] = useState<string>('Nov');
  const [isRoleTLM, setIsRoleTLM] = useState(true);
  const [searchError, setSearchError] = useState(false);
  const [roles, setRoles] = useState<Array<string>>([]);
  const months = isRoleTLM ? getMonthsArrayForTLM() : getMonthsArray();
  const [monthSelected, setMonthSelected] = useState({
    checked: false,
    months: months,
  });
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles(monthSelected);
  const searchText = useSelector(
    planAndMeetSelector.getPlanAndMeetSearchText(),
  );
  const gridOptions = useSelector(
    planAndMeetSelector.getPlanAndMeetGridOption(),
  );
  const planAndMeetState = useSelector(
    planAndMeetSelector.getPlanAndMeetState(),
  );
  const filters = useSelector(filterSelector.getFilterState());
  const masterConfigData = useSelector(
    approvalWorkflowSelector.getMasterConfigData(),
  );

  const currentSelectedMonth = useRef<string>();
  const selectedRoles = useRef<string[]>(['All']);

  useEffect(() => {
    if (masterConfigData?.roles?.length) {
      const rolesList = [...masterConfigData?.roles]
        ?.sort((a: any, b: any) => a?.rank - b?.rank)
        ?.map((role: any) => role?.shortName);
      setRoles(['All', ...rolesList]);
    }
  }, [masterConfigData]);

  useEffect(() => {
    if (
      dropdownValue?.includes('MR') ||
      dropdownValue?.includes('SLM') ||
      dropdownValue?.includes('FLM') ||
      dropdownValue?.includes('All')
    ) {
      setIsRoleTLM(false);
      setMonthDropDownValue(moment().format('MMM'));
    } else {
      setIsRoleTLM(true);
    }
  }, [dropdownValue]);

  const fetchListData = () => {
    const getSelectedMonth = getNextSixMonths(currentSelectedMonth.current);

    dispatch(
      planAndMeetStatusStateActions.setPlanAndMeetGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );

    dispatch(
      fetchPlanAndMeetListCreator({
        isRoleTLM: valuesPresent(selectedRoles.current, [
          'MR',
          'FLM',
          'SLM',
          'All',
        ]),
        month: getSelectedMonth?.month,
        year: getSelectedMonth?.year,
      }),
    );
  };

  const handleSearchTextChange = (value: any) => {
    dispatch(planAndMeetStatusStateActions.setPlanAndMeetSearchText(value));
    if (!value?.length || value?.length >= 3) {
      setSearchError(false);
    }
  };

  const handleDropdownChange = (event: any) => {
    const {
      target: {value},
    } = event;
    let values = [];
    if (value.indexOf('All') !== -1) {
      values = value.filter((item: any) => item !== 'All');
    } else if (value.length > 0 && value.length < roles?.length - 1) {
      values = value;
    } else {
      values.push('All');
    }
    selectedRoles.current = values;
    setDropdownValue(values);
    dispatch(
      planAndMeetStatusStateActions.setPlanAndMeetSelectedRoles(
        values.map((val: any) =>
          getRoleIdFromRolesList(masterConfigData?.roles, val),
        ),
      ),
    );
    fetchListData();
  };

  const handleMonth = (event: any) => {
    setMonthSelected({
      ...monthSelected,
      [event.target.name]: event.target.checked,
    });
    dispatch(
      planAndMeetStatusStateActions.setPlanAndMeetMonthSelected(
        event.target.checked,
      ),
    );
    fetchListData();
  };
  const handleMonthSelect = (event: any) => {
    setMonthDropDownValue(event.target.value);
    currentSelectedMonth.current = event.target.value;
    dispatch(
      planAndMeetStatusStateActions.setPlanAndMeetMonthSelected(
        event.target.value,
      ),
    );
    fetchListData();
  };

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      if (searchText?.length >= 3 || searchText?.length === 0) {
        setSearchError(false);
        fetchListData();
      } else {
        setSearchError(true);
      }
    }
  };
  const onPressdownloadExcel = () => {
    dispatch(
      filterBasedDownloadExcelCreator(
        downloadExcel(planAndMeetState, filters, planAndMeetState?.filters),
      ),
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={navbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />
        <div>
          <Button onClick={onPressdownloadExcel}>
            <img
              src={excelLogo}
              alt="Logo"
              className={classes.downloadExcelLogo}
            />
            <Label
              title={'Download Excel'}
              variant="h6"
              textColor={'#322b7c'}
              fontWeight={'800'}
              hideToolTip
            />
          </Button>
        </div>
      </div>
      <div className={classes.searchContainer}>
        <SearchBar
          searchText={searchText}
          handleChange={handleSearchTextChange}
          handleSearch={handleSearchPress}
          showError={searchError}
          placeHolder={'Search by name or employee code'}
          errorMessage={'Please Enter Minimum 3 Characters'}
        />
        <FormControl sx={{mt: 1.2, ml: 3, mr: 3, width: 100, alignSelf: 'end'}}>
          <span id="demo-multiple-name-label">Roles</span>
          <Select
            labelId="plan-meet-role-dropdown"
            id="plan-meet-role-dropdown"
            multiple
            className={classes.dropdown}
            data-testid={'roleDropDown'}
            defaultValue={dropdownValue}
            value={dropdownValue}
            onChange={handleDropdownChange}
            renderValue={selected => selected.join(', ')}
            onClose={() => !dropdownValue?.length && setDropdownValue(['All'])}
            MenuProps={MenuProps}>
            {roles?.map((role: any) => (
              <MenuItem
                key={role}
                value={role}
                data-testid={`dropdown_${role}`}
                disabled={role === 'All'}
                style={getStyles(role, dropdownValue, theme)}>
                <Checkbox checked={dropdownValue.some(val => val === role)} />
                <ListItemText primary={role} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{mt: 1.2, width: 120, alignSelf: 'end'}}>
          <span id="demo-multiple-name-label">Month</span>
          {valuesPresent(selectedRoles.current, ['MR', 'FLM', 'SLM', 'All']) ? (
            <Select
              labelId="plan-meet-month-dropdown"
              id="plan-meet-month-dropdown"
              className={classes.dropdown}
              data-testid={'monthDropDown'}
              defaultValue={monthDropDownValue}
              value={monthDropDownValue}
              onChange={handleMonthSelect}
              renderValue={selected => selected}
              onClose={() =>
                !monthDropDownValue?.length && setMonthDropDownValue('Nov')
              }
              MenuProps={MenuProps}>
              {months?.map((month: any) => (
                <MenuItem
                  key={month}
                  value={month}
                  data-testid={`dropdown_${month}`}
                  style={getStyles(month, months, theme)}>
                  <Checkbox checked={monthDropDownValue === month} />
                  <ListItemText primary={month} />
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Switch
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              checked={monthSelected.checked}
              name="checked"
              inputProps={{'aria-label': 'secondary checkbox'}}
              data-testid={'monthSwitchOption'}
              onChange={handleMonth}
            />
          )}
        </FormControl>
      </div>
    </div>
  );
};
